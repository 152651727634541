import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import { 
  EditOutlined, 
  SettingOutlined, 
  ShopOutlined, 
  QuestionCircleOutlined, 
  LogoutOutlined,
  CommentOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import utils from 'utils';


const userId = window.sessionStorage.getItem("userId");

const menuItem = [
	{
		title: "회원정보 변경",
		icon: EditOutlined ,
		path: `/project/user/${userId}/edit-profile`
    },
  
    
  //   {
	// 	title: "Account Setting",
	// 	icon: SettingOutlined,
	// 	path: "/"
  //   },
  //   {
	// 	title: "Billing",
	// 	icon: ShopOutlined ,
	// 	path: "/"
	// },
  //   {
	// 	title: "Help Center",
	// 	icon: QuestionCircleOutlined,
	// 	path: "/"
	// }
]

export const NavProfile = ({signOut}) => {
  const userId = window.sessionStorage.getItem("userId");
  const userImagePath = window.sessionStorage.getItem("userImagePath");
  const nickname = window.sessionStorage.getItem('userNickname');
  const email = window.sessionStorage.getItem('userEmail');
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          {/* <Avatar size={45} src={userImagePath} /> */}
          {userImagePath==" "?  <Avatar className="font-weight-semibold font-size-big" size={45} >{utils.getNameInitial(nickname)}</Avatar> 
          : <Avatar size={45} src={userImagePath}/>}
          <div className="pl-3">
            <h4 className="mb-0">{nickname}</h4>
            <span className="text-muted">{email}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          {/* <Menu.Item key={menuItem.length + 1} onClick={e =>{ 
            window.sessionStorage.removeItem("userId");
            window.location.reload();

            }}> */}
            {/* <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal" >Sign Out</span>
            </span>
          </Menu.Item> */}
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
        {userImagePath==""?  <Avatar className="font-weight-semibold font-size-big" size={45} >{utils.getNameInitial(nickname)}</Avatar> 
          : <Avatar size={45} src={userImagePath}/>}
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, {signOut})(NavProfile)
