import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = ({match} ) => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        {/* <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} /> */}
        <Route path={`${APP_PREFIX_PATH}/project`} component={lazy(() => import(`./project`))} />
        
        {/* <Route path={`${APP_PREFIX_PATH}/add`} component={lazy(() => import(`./e-commerce`))} /> */}

        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);